<template>
  <section class="q-pa-md">
    <div class="row justify-center items-center q-gutter-md q-pb-md heading">
      <div class="title">
        <h2 class="text-h4 text-weight-medium no-margin">
          Popular Categories
        </h2>
      </div>
    </div>

    <div class="row q-col-gutter-md">
      <div class="col-12 col-sm-6 col-lg-3">
        <nuxt-link to="/category/new-handguns">
          <q-img
            src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/handguns.jpg"
            placeholder-src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/handguns.jpg"
            srcset="
              https://static.kygunco.com/cdn-cgi/image/dpr=1,h=300,f=auto/assets/backdrops/handguns.jpg 1x,
              https://static.kygunco.com/cdn-cgi/image/dpr=2,h=300,f=auto/assets/backdrops/handguns.jpg 2x"
            height="200px"
            no-spinner
            no-transition
          >
            <div class="absolute-bottom text-subtitle2 text-center">
              <h3 class="text-h5 text-weight-medium no-margin">
                Handguns
              </h3>
            </div>
          </q-img>
        </nuxt-link>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <nuxt-link to="/category/new-rifles">
          <q-img
            src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/rifles.jpg"
            placeholder-src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/rifles.jpg"
            srcset="
              https://static.kygunco.com/cdn-cgi/image/dpr=1,h=300,f=auto/assets/backdrops/rifles.jpg 1x,
              https://static.kygunco.com/cdn-cgi/image/dpr=2,h=300,f=auto/assets/backdrops/rifles.jpg 2x"
            height="200px"
            no-spinner
            no-transition
          >
            <div class="absolute-bottom text-subtitle2 text-center">
              <h3 class="text-h5 text-weight-medium no-margin">
                Rifles
              </h3>
            </div>
          </q-img>
        </nuxt-link>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <nuxt-link to="/category/new-shotguns">
          <q-img
            src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/shotguns.jpg"
            placeholder-src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/shotguns.jpg"
            srcset="
              https://static.kygunco.com/cdn-cgi/image/dpr=1,h=300,f=auto/assets/backdrops/shotguns.jpg 1x,
              https://static.kygunco.com/cdn-cgi/image/dpr=2,h=300,f=auto/assets/backdrops/shotguns.jpg 2x"
            height="200px"
            no-spinner
            no-transition
          >
            <div class="absolute-bottom text-subtitle2 text-center">
              <h3 class="text-h5 text-weight-medium no-margin">
                Shotguns
              </h3>
            </div>
          </q-img>
        </nuxt-link>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <nuxt-link to="/category/ammunition">
          <q-img
            src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/ammunition.jpg"
            placeholder-src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/ammunition.jpg"
            srcset="
              https://static.kygunco.com/cdn-cgi/image/dpr=1,h=300,f=auto/assets/backdrops/ammunition.jpg 1x,
              https://static.kygunco.com/cdn-cgi/image/dpr=2,h=300,f=auto/assets/backdrops/ammunition.jpg 2x"
            height="200px"
            no-spinner
            no-transition
          >
            <div class="absolute-bottom text-subtitle2 text-center">
              <h3 class="text-h5 text-weight-medium no-margin">
                Ammunition
              </h3>
            </div>
          </q-img>
        </nuxt-link>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <nuxt-link to="/category/firearm-components">
          <q-img
            src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/gun-parts.jpg"
            placeholder-src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/gun-parts.jpg"
            srcset="
              https://static.kygunco.com/cdn-cgi/image/dpr=1,h=300,f=auto/assets/backdrops/gun-parts.jpg 1x,
              https://static.kygunco.com/cdn-cgi/image/dpr=2,h=300,f=auto/assets/backdrops/gun-parts.jpg 2x"
            height="200px"
            no-spinner
            no-transition
          >
            <div class="absolute-bottom text-subtitle2 text-center">
              <h3 class="text-h5 text-weight-medium no-margin">
                Gun Parts
              </h3>
            </div>
          </q-img>
        </nuxt-link>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <nuxt-link to="/category/optics">
          <q-img
            src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/optics.jpg"
            placeholder-src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/optics.jpg"
            srcset="
              https://static.kygunco.com/cdn-cgi/image/dpr=1,h=300,f=auto/assets/backdrops/optics.jpg 1x,
              https://static.kygunco.com/cdn-cgi/image/dpr=2,h=300,f=auto/assets/backdrops/optics.jpg 2x"
            height="200px"
            no-spinner
            no-transition
          >
            <div class="absolute-bottom text-subtitle2 text-center">
              <h3 class="text-h5 text-weight-medium no-margin">
                Optics
              </h3>
            </div>
          </q-img>
        </nuxt-link>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <nuxt-link to="/category/knives-and-cutlery">
          <q-img
            src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/knives.jpg"
            placeholder-src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/knives.jpg"
            srcset="
              https://static.kygunco.com/cdn-cgi/image/dpr=1,h=300,f=auto/assets/backdrops/knives.jpg 1x,
              https://static.kygunco.com/cdn-cgi/image/dpr=2,h=300,f=auto/assets/backdrops/knives.jpg 2x"
            height="200px"
            no-spinner
            no-transition
          >
            <div class="absolute-bottom text-subtitle2 text-center">
              <h3 class="text-h5 text-weight-medium no-margin">
                Knives
              </h3>
            </div>
          </q-img>
        </nuxt-link>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <nuxt-link to="/category/archery">
          <q-img
            src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/archery.jpg"
            placeholder-src="https://static.kygunco.com/cdn-cgi/image/h=300,f=auto/assets/backdrops/archery.jpg"
            srcset="
              https://static.kygunco.com/cdn-cgi/image/dpr=1,h=300,f=auto/assets/backdrops/archery.jpg 1x,
              https://static.kygunco.com/cdn-cgi/image/dpr=2,h=300,f=auto/assets/backdrops/archery.jpg 2x"
            height="200px"
            no-spinner
            no-transition
          >
            <div class="absolute-bottom text-subtitle2 text-center">
              <h3 class="text-h5 text-weight-medium no-margin">
                Archery
              </h3>
            </div>
          </q-img>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>

</script>
