<template>
  <q-page>
    <h1 class="hidden">
      Guns for sale online
    </h1>

    <HomeSlider />

    <HomeCategories />

    <div class="bg-blue-grey-10 q-pa-md">
      <div class="row items-center justify-around q-col-gutter-md">
        <div class="col-12 col-sm-auto text-h5 text-weight-regular">
          Did you know you can finance your firearm purchase with approvals up to $10,000?
        </div>

        <div class="col-12 col-sm-auto">
          <q-btn
            label="Learn More"
            size="lg"
            class="full-width"
            color="primary"
            style="min-width: 200px"
            square
            @click="$credova.prequalify()"
          />
        </div>
      </div>
    </div>

    <HomeLinks />

    <div class="q-pa-md">
      <CatalogCarousel
        :query="{
          isInStock: { $eq: true },
          groups: { slug: { $eq: 'bestsellers' } },
          categories: { slug: { $eq: 'new-handguns' } },
        }"
        :sort-by="['quantitySold:desc']"
        :size="24"
        :server="false"
        data-key="home_handgun_bestsellers"
        name="Home Handgun Bestsellers"
      >
        <template #title>
          <div class="row items-center q-gutter-x-md">
            <div>
              <h2 class="text-h4 text-weight-medium no-margin">
                Best Selling Handguns
              </h2>
            </div>
            <q-btn
              :to="{
                path: '/group/bestsellers',
                query: {
                  category: 'new-handguns',
                },
              }"
              color="secondary"
              label="See More"
              outline
              square
            />
          </div>
        </template>
      </CatalogCarousel>
    </div>

    <div class="q-pa-md">
      <CatalogCarousel
        :query="{
          groups: { slug: { $eq: 'kygunco-picks' } },
        }"
        :sort-by="['quantitySold:desc']"
        :size="24"
        :server="false"
        data-key="home_featured"
        name="Home Featured"
      >
        <template #title>
          <div class="row items-center q-gutter-x-md">
            <div>
              <h2 class="text-h4 text-weight-medium no-margin">
                KYGUNCO Picks
              </h2>
            </div>
            <q-btn
              to="/group/kygunco-picks"
              color="secondary"
              label="See More"
              outline
              square
            />
          </div>
        </template>
      </CatalogCarousel>
    </div>

    <div class="q-pa-md">
      <CatalogCarousel
        :query="{
          isInStock: { $eq: true },
          groups: { slug: { $eq: 'bestsellers' } },
          categories: { slug: { $eq: 'ammunition' } },
        }"
        :sort-by="['quantitySold:desc']"
        :size="24"
        :server="false"
        data-key="home_ammunition_bestsellers"
        name="Home Ammunition Bestsellers"
      >
        <template #title>
          <div class="row items-center q-gutter-x-md">
            <div>
              <h2 class="text-h4 text-weight-medium no-margin">
                Best Selling Ammunition
              </h2>
            </div>
            <q-btn
              :to="{
                path: '/group/bestsellers',
                query: {
                  category: 'ammunition',
                },
              }"
              color="secondary"
              label="See More"
              outline
              square
            />
          </div>
        </template>
      </CatalogCarousel>
    </div>
  </q-page>
</template>

<script lang="ts" setup>
useSeoMeta({
  title: 'Online Gun Store | Firearms, Ammo, Parts and Accessories',
  titleTemplate: '%siteName %separator %s',
});

defineOgImage();

const { $credova } = useNuxtApp();
</script>
